/* eslint-disable no-template-curly-in-string */
export default {
  app: {
    baseUrl: 'https://vottar.hom.vaultid.com.br',
    logo: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iQ2FtYWRhXzIiIGRhdGEtbmFtZT0iQ2FtYWRhIDIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDg4MC45IDIyMy40Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZWE3MTAwOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIj4KICAgIDxnPgogICAgICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im01NDAuNCwxODAuOWgzNi43djM5LjVoLTMwLjVjLTMyLjEsMC01MS44LTExLjEtNTEuOC01MS44di04OC4yaC0yMS44Yy0zLjgsMC01LjgtNC42LTMuMi03LjRMNTMyLjksMS40YzIuNy0yLjksNy42LTEsNy42LDN2MzYuNWgzNi40djM5LjVoLTM2LjR2MTAwLjVoLS4xWk00MDkuNiwzOGMtMjEuOSwwLTM4LjMsMTIuNi00Ni4zLDMyLjRoLTEuNXYtMjkuNmgtNDMuNXYxNzkuNmg0NS43VjgxLjJoMzAuNWMxMy4zLDAsMTguMiwyLjgsMTguMiwxN3YxMjIuMmg0NS43Vjg5LjNjLS4xLTM2LjEtMTcuNC01MS4zLTQ4LjgtNTEuM1ptLTE3MC4zLDE4Mi40aDQ1LjdWNDAuOGgtNDUuN3YxNzkuNlpNODM3LjIsNzIuNmgtMS45di0zMS44aC00NS40djE3OS42aDQ4LjRWODMuN2g0Mi42di00NS43aC01LjljLTIyLjQsMC0zMi4zLDkuOS0zNy44LDM0LjZabS02OC41LDUzLjR2MTMuNmgtMTM3YzIuNSwyNi43LDE5LjIsNDQuNCw0NSw0NC40LDIzLjEsMCwzOS44LTEyLjMsNDMuMi0yNy41aDQ4LjdjLTEwLjIsMzcuOS00NSw2Ni45LTkyLjYsNjYuOS01NS41LDAtOTMuMi0zOS41LTkzLjItOTEuM3MzNi40LTk0LjEsOTQuNC05NC4xYzU0LjgsMCw5MS41LDM3LjcsOTEuNSw4OFptLTEzNS42LTE5LjVoODguNGMtNi45LTE4LTIxLjUtMzAuOS00NC4xLTMwLjktMTkuOC4xLTM3LjIsMTAuNS00NC4zLDMwLjlaIi8+CiAgICAgIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0ibTAsMjEyLjdoOTMuOWwuNS0yLjZMMS44LDE5MC45bDEuOC0xMC4zLDkyLjYsMjAsLjgtMy42TDkuNSwxNTlsMy44LTEwLjUsODcuNywzNi45LDEuOC0yLjZMMjUuMSwxMjUuMWw4LTEyLjYsNzcuMiw1Ny43LDIuMy0zLjEtNjAuOC03Ny4yLDE0LjktMTIuMSw2MC4zLDczLjYsMi42LTIuMy0zNS4xLTg5LjMsMjEuMy05LDMzLjEsODcuNSwyLjYtLjhWNDAuNWgzNHYxNzkuNkgwdi03LjRaIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4=',
    callbackOperatorRecoveryPassword: 'https://vottar-cotista.hom.vaultid.com.br/#/auth/register-password',
    idGTM: 'GTM-WZFXPQQH',
  },
  version: {
    commit: 'de83a4a72e7d69b0ca1f5ea34612ead1112fcf18',
    branch: 'release/1.2.1',
    tag: '1.2.1',
    repo: 'vottar-cotista-front',
    build: '96',
    date: '2024-07-16T20:32:40.1721161960Z',
  },
};

